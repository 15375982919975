<template>
    <div class="home">
      <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
    position: fixed;
            text-align: right;">
            <i @click="handleSelect('/topic/list')" class="el-icon-close"></i>
      </div>

      <div class="pt20"></div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="80px">
          <div v-for="(item,index) in form.label" class="flex" :key="index">
          <el-form-item :required="true"  label="名称">
            <el-input v-model="item.name" class="w" placeholder="请输入最多16字"></el-input>
          </el-form-item>
          <el-form-item :required="true"  label="推荐话题">
            <el-select style="width:100px;"  v-model="item.is_recommend" placeholder="请选择">
                  <el-option label="请选择" :value="-1"></el-option>
                  <el-option label="否" :value="0"></el-option>
                  <el-option label="是" :value="1"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :required="true"  label="版本活动">
            <el-select style="width:100px;"  v-model="item.is_activity" placeholder="请选择">
                  <el-option label="请选择" :value="-1"></el-option>
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="禁用" :value="1"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :required="true" label="分类">
              <div  class="flex">
            <el-select @change='ftype(index,$event)' style="width:120px;" v-model="item.f_type" placeholder="请选择">
                  <el-option
                    v-for="ite in item.options"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
            </el-select>
            <el-select v-if="item.options2.length != 0" style="width:120px;" v-model="item.c_type" placeholder="请选择">
                  <el-option
                    v-for="ite in item.options2"
                    :key="ite.id"
                    :label="ite.name"
                    :value="ite.id">
                  </el-option>
            </el-select>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="deleteData(index)"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              v-if="form.label.length - 1 === index"
              @click="add"
            ></el-button>
            </div>
          </el-form-item>
          </div>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">添加</el-button>
          <el-button @click="goNav('/topic/list')" >取消</el-button>
        </el-form-item>
      </el-form>
    </div>
      <div class="pt20"></div>
        
      
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      form: {
          label:[{
            is_recommend:-1,
            is_activity:-1,
            name:'',
            f_type:-1,
            c_type:-1,
            options:[],
            options2:[],
          }]
        },
    }
  },
  methods: {
    handleSelect(url){
        this.$router.push(url)
    },
     onSubmit() {
      let _this = this;
      let postdata = this.form.label;
      let arr = [];
      let length = postdata.length;
      for(let i in postdata){
          if(postdata[i].name.length == 0){
            this.$alert("请填写话题的名称");
            return;
          }
          if(postdata[i].name.length > 16){
            this.$alert("话题的名称最多16字");
            return;
          }
          if(postdata[i].is_recommend == -1){
            this.$alert("请选择是否为推荐话题");
            return;
          }
          if(postdata[i].is_activity == -1){
            this.$alert("请选择是否启用版本活动");
            return;
          }
          if(postdata[i].c_type == -1){
            this.$alert("请选择话题分类");
            return;
          }
          if(postdata[i].f_type == -1){
            this.$alert("请选择话题分类");
            return;
          }
          arr.push(postdata[i].name);
      }
      arr = Array.from(new Set(arr));
      if(arr.length != length){
        this.$alert("话题名重复");
        return;
      }
      let abc = [];
      for(let i in postdata){
          let obj = {
              name:postdata[i].name,
              c_type:postdata[i].c_type,
              type:postdata[i].f_type,
              is_recommend:postdata[i].is_recommend,
              is_activity:postdata[i].is_activity,
          };
          abc.push(obj);
      }
      
      axios.put(config.topic,{topic:abc})
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/topic/list');
            }else{
            _this.$alert(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },// 增加
    add() {
          let _this = this;
        this.form.label.push({
          is_recommend:-1,
          is_activity:-1,
            name:'',
            f_type:-1,
            c_type:-1,
            options:[],
            options2:[],
          });
          let i = this.form.label.length -1;
        axios.get(config.selecttopicCategory,{params:{id:0,title:1}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[i].options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 删除
    deleteData(index) {
      if (this.form.label.length - 1 < 1) {
        this.$alert("至少输入1项");
        return;
      } else {
        this.form.label.splice(index, 1);
      }
    },
    ftype(obj,e){
      let _this = this;
      if(e != -1){
      axios.get(config.selecttopicCategory,{params:{id:e,title:1}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[obj].options2 = response.data.data;
              _this.form.label[obj].c_type = -1;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }else{
        _this.form.label[obj].options2 = [];
        _this.form.label[obj].c_type = 0;
      }
    },
    init(){
        let _this = this;
        axios.get(config.selecttopicCategory,{params:{id:0,title:1}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.form.label[0].options = response.data.data
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.form{
    display: flex;
}
.flex{
    display: flex;
}
.w{
    width:300px;
}
.ww{
    width: 70px;
}
.el-icon-close:before{
  font-size: 24px;
}
</style>
